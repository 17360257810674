import LocalizedStrings from 'react-localization';

const lang = new LocalizedStrings({
  en: {
    add: 'Add',
    create: 'Create',
    publish: 'Publish',
    submit: 'Submit',
    hide: 'Hide',
    edit: 'Edit',
    cancel: 'Cancel',
    erase: 'Erase',
    'delete': 'Delete',
    remove: 'Remove',
    saveChanges: 'Save changes',
    changesSaved: 'The changes made were saved successfully',
    copy: 'Copy',
    oops: 'Oops! Something went wrong!',
    date: 'Date',
    previous: 'Previous',
    next: 'Next',
    unauthorizedMessage: 'Looks like you\'re not yet qualified to be in this area... Maybe next year!',
    notFoundMessage: 'Oh no! It seems that you lost your way around our website... Maybe it\'s not that Intuitive.',
    errorMessage: 'Something wrong happened... We\'ll be looking into fixing it as soon as possible.',
    noPermissionSpace: 'You don\'t have the permissions to access this space.',
    home: 'Home',
    account: 'Account',
    signout: 'Sign out',
    send: 'Send',
    info: 'Info',
    help: 'Help',
    feedback: 'Feedback',
    showMore: 'Show more',
    chooseSpace: 'Select a space',
    changeLang: 'Change language',
    logout: 'Log out',
    or: 'Or',
    tutorial: 'Tutorials',
    confirm: 'Confirm',
    internetWentWrong: 'Something went wrong. Please check your internet connection and try again',
    month: 'month',
    save: 'Save',
    appKeywords: {
      classes: 'Classes',
      tests: 'Tests',
      threads: 'Threads',
      admin: 'Administrator',
      lesson: 'Lesson',
      subject: 'Subject',
      'class': 'Class',
      student: 'Student',
      students: 'Students',
      grade: 'Grade',
      personalAccount: 'Personal account',
      personalSpace: 'Personal space',
      groups: 'Groups',
      exercises: 'Exercises',
      explore: 'Explore',
      assessments: 'Assessments',
      rubrics: 'Rubrics',
      events: 'Events',
      synchronization: 'Synchronization',
      teachers: 'Teachers',
      exams: 'Exams',
    },
    login: {
      header: 'Login',
      description: 'Welcome to Intuitivo',
      emailLabel: 'E-mail or username',
      emailPlaceholder: 'Insert here your e-mail or username',
      emailError: 'You have to fill this field with your e-mail.',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insert here your password',
      passwordError: 'You have to fill this field with your password.',
      submitLogin: 'Login',
      wrongCredentials: 'Your e-mail or password don\'t match',
      forgotPassword: 'Forgot my password',
      dontHaveAccount: 'Don\'t have an account?',
      createOne: 'Create one',
      loginWithMicrosoft: 'Login with Microsoft',
      loginWithMicrosoftError: 'Please login with your Microsoft account',
      loginWithMicrosoftNoAccount: 'No account exists with that e-mail. Create an account now!',
      loginWithMicrosoftRegularAccountExists: 'This account is not linked to Microsoft. Please login with your credentials!',
      exceededMaxAttempts: 'You exceeded the maximum login attempts allowed. Click in forgot my password to continue',
      poweredBy: 'Powered by',
    },
    register: {
      header: 'Create account',
      description: 'Welcome to Intuitivo',
      nameLabel: 'Name',
      namePlaceholder: 'Insert here your name',
      nameEmptyError: 'The name can\'t be empty',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insert here your e-mail',
      emailEmptyError: 'The e-mail can\'t be empty',
      emailInvalidError: 'The e-mail introduced must be valid',
      emailExistsError: 'There is already an account with that e-mail address',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insert here your password',
      passwordLengthError: 'The password must have at least 8 characters',
      passwordSpecialCharsError: 'The password must contain at least one special character',
      passwordUpperCharsError: 'The password must contain at least one uppercase letter',
      passwordNumberError: 'The password must contain at least one number',
      repeatPasswordLabel: 'Repeat password',
      repeatPasswordPlaceholder: 'Insert here your password',
      passwordsDifferentError: 'The passwords must be equal',
      createAccount: 'Create account',
      alreadyHaveAccount: 'Already have an account?',
      login: 'Login',
      registerWithMicrosoft: 'Create account with Microsoft',
    },
    onboarding: {
      title: 'Welcome',
      header: 'Before you continue, help us improve your experience',
      doThisLater: 'Fill it later',
    },
    recover: {
      header: 'Recover password',
      description: 'An e-mail will be sent to your e-mail address where you will be able to change your password.',
      descriptionPassword: 'Type a new password to replace your current one.',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insert here your e-mail',
      emailSent: 'The e-mail was successfully sent. Please check your inbox',
      emailError: 'The provided e-mail is incorrect.',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insert here your password',
      passwordRepeatLabel: 'Repeat Password',
      passwordRepeatPlaceholder: 'Insert here your password again',
      passwordUpdated: 'The password was updated with success',
      passwordMinimumChars: 'The password must contain at least 8 characters, one letter and one number.',
      passwordsDifferentError: 'The passwords must be equal.',
      passwordLengthError: 'The password must have at least 8 characters',
      passwordSpecialCharsError: 'The password must contain at least one special character',
      passwordUpperCharsError: 'The password must contain at least one uppercase letter',
      passwordNumberError: 'The password must contain at least one number',
    },
    profile: {
      header: 'Account management',
      description: 'Here you can manage your account',
      fullName: 'Name',
      email: 'E-mail',
      updatePassword: 'Change password',
      passwordMissing: 'Both fields must be filled',
      passwordError: 'Passwords don\'t match',
      passwordHint: 'Use 8 or more characters with a mix of letters, numbers and special characters',
      newPassword: 'New password',
      confirmPassword: 'Confirm new password',
      newPasswordPlaceholder: 'Type here the new password',
      confirmPasswordPlaceholder: 'Type again the new password',
      userName: 'Username',
      editInformation: 'Edit information',
      nameRequiredError: 'The name field is required',
      emailRequiredError: 'The e-mail field is required',
      emailInvalidError: 'The e-mail field is invalid',
      profileInformation: {
        informationUpdateSuccess: 'The information was successfully updated',
      },
      profilePreferences: {
        profilePreferences: 'Profile preferences',
        subjects: 'Subjects',
        years: 'Years',
        country: 'Country',
        curriculum: 'Curriculum',
        selectCountry: 'Select a country',
        selectCurriculum: 'Select a curriculum',
        editProfilePreferences: 'Edit preferences',
        noSubjects: 'You have not selected any subject yet',
        noYears: 'You have not selected any year yet',
        personalSpace: 'Personal Space',
        personalSpaceDescription: 'A personal space is where you can create your contents separately from your school space and create groups with other member of the community.',
        personalSpaceCheckboxLabel: 'Activate personal space',
      },
      security: {
        securityHeader: 'Security',
        changePassword: 'Change password',
        linkWithMicrosoft: 'Link with Microsoft',
        unlinkMicrosoftAccount: 'Unlink Microsoft account',
        unlinkMicrosoftAccountInfo: 'Are you sure you want to unlink your Microsoft account? You will be able to reconnect at any time in the future.',
        unlinkMicrosoftAccountSuccess: 'Your Microsoft account was successfully unlinked',
        unlinkMicrosoftAccountDisabled: 'You need to create a password before unlinking your Microsoft account',
        emailAlreadyInUse: 'This email is already being used by another user',
        accountLinkedWithMicrosoftSuccess: 'Your Microsoft account was successfully linked',
        createPassword: 'Create password',
      },
      plans: {
        plan: 'My plan',
        plans: 'Plans',
        free: 'Free',
        basic: 'Basic',
        schools: 'Schools',
        forTeachers: 'For teachers',
        forSchools: 'For institutions',
        premium: 'Premium',
        nextPayment: 'Next payment',
        onRequest: 'On request',
        contactUs: 'Contact us!',
        recommendSchool: 'Recommend School',
        switchToPlan: 'Switch to this plan',
        switchPlan: 'Manage plan',
        cancelSubscription: 'Cancel subscription',
        cancelTip: (endDate) => `You will remain Premium until the end of the billing cycle on ${endDate}`,
        continueSubscription: 'Resume subscription',
        subscribedUntil: 'Subscribed until',
        planDescription: {
          free: 'The essentials of Intuitivo, available for all.',
          premium: 'The best assessment tool, without limits.',
          schools: 'The ultimate solution for educational institutions.',
        },
        monthly: 'Monthly',
        yearly: 'Yearly',
        billedMonthly: 'billed monthly',
        billedYearly: 'billed yearly',
        yourSubscription: 'Your subscription',
        total: 'Total',
        andPay: 'and pay',
        taxInfo: 'Tax included',
        includedTax: 'Tax included',
        alreadyOnThatPlan: 'You are already subscribed to that plan',
        discountPrimaryText: (discountName) => `${discountName} with `,
        discountHighlightText: (limitDate) => `a discount until ${limitDate}!`,
        discountTooltip: (discountName, limitDate, yearlyDiscount, monthlyDiscount) => `${discountName} campaign until ${limitDate}, with ${yearlyDiscount}% discount on the annual premium plan${monthlyDiscount ? ` and ${monthlyDiscount}% on the monthly premium plan.` : '.'}`,
        premiumUser: 'Premium User',
        getPremium: 'Get Premium',
        schoolsFeatures: {
          premium: 'Everything in Premium plan',
          adminPanel: 'Administration panel',
          schoolSpace: 'Exclusive institution space',
          support: 'Priority support and training',
        },
        paymentMethod: 'Payment method',
        seePaymentMethod: 'See payment method',
        replacePaymentMethod: 'Replace payment method',
        paypalEmail: 'PayPal email: ',
        expires: 'Expires: ',
        cardEndingIn: (brand, last4) => `${brand} card ending in ${last4}`,
        removePaymentMethod: 'Remove payment method',
        addPaymentMethod: 'Add payment method',
        promoCode: 'Promo code',
        apply: 'Apply',
        promoCodePlaceholder: 'Insert here your promo code',
        promoCodeInvalidError: 'The promo code is expired or invalid',
      },
      danger: {
        dangerHeader: 'Danger zone',
        curriculum: 'Curriculum',
        changeCurriculum: 'Change curriculum',
        changeCurriculumWarning: 'Are you sure you want to change your curriculum? Changing your curriculum will turn all your public exercises and assessments private. The contents of the explore page will also change to match your new curriculum.',
      },
    },
    schoolInvite: {
      header: 'Welcome to Intuitivo',
      description1: 'You received an invite from your school:',
      description2: 'Create an account and start collaborating!',
      continueWith: 'Continue with',
      changeAccount1: 'This is not your account?',
      changeAccount2: 'Log out',
    },
    maintenance: {
      label: 'Maintenance',
      quote: 'Tobias is working hard to bring Intuitivo back online, come back later...',
    },
    features: {
      free: {
        'create-test': (limit) => `Create up to ${limit} ${limit === 1 ? 'test' : 'tests'}`,
        'create-exercise': (limit) => `Create up to ${limit} ${limit === 1 ? 'exercise' : 'exercises'}`,
        'create-rubric': (limit) => `Create up to ${limit} ${limit === 1 ? 'rubric' : 'rubrics'}`,
        'create-group': (limit) => `Create up to ${limit} ${limit === 1 ? 'group' : 'groups'}`,
        sections: (limit) => `Create up to ${limit} ${limit === 1 ? 'section' : 'sections'}`,
        'export-versions': (limit) => `Export up to ${limit} ${limit === 1 ? 'version' : 'versions'}`,
      },
      premium: {
        'create-test': () => 'Create unlimited tests',
        'create-exercise': () => 'Create unlimited exercises',
        'create-rubric': () => 'Create unlimited rubrics',
        'create-group': () => 'Create unlimited groups',
        sections: () => 'Create unlimited sections',
        'section-timer': () => 'Timer in sections',
        navigation: () => 'Create non-linear assessments and sections',
        connecting: () => 'Publish assessments with connecting exercises',
        segmentation: () => 'Publish assessments with segmentation exercises',
        'hide-intuitivo-logo-export': () => 'Export assessments and resolutions without the Intuitivo logo',
        feedback: () => 'Give feedback to students',
        'export-versions': () => 'Export unlimited versions',
        'advanced-statistics': () => 'Advanced assessment statistics',
      },
    },
    limbo: {
      title: 'No space found!',
      description: 'Your Intuitivo school space was deactivated! If you still want to continue using Intuitivo individually, with our free and limited plan, create a personal space by clicking the button below and access the settings page to change your e-mail to a personal e-mail, if you haven\'t already.',
      addPersonalSpace: 'Add personal space',
    },
    curricula: {
      pt_PT: 'Portuguese',
      other: 'Other',
    },
  },
  pt: {
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    submit: 'Submeter',
    hide: 'Esconder',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Eliminar',
    remove: 'Remover',
    saveChanges: 'Guardar alterações',
    changesSaved: 'As alterações feitas foram gravadas com sucesso',
    copy: 'Copiar',
    oops: 'Ups! Aconteceu algo de errado!',
    date: 'Data',
    previous: 'Anterior',
    next: 'Seguinte',
    home: 'Início',
    unauthorizedMessage: 'Parece que ainda não és suficientemente qualificado para estar nesta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que te perdeste no nosso site... Talvez não seja assim tão Intuitivo.',
    errorMessage: 'Algo de errado aconteceu... Vamos verificar e corrigir o mais rápido possível.',
    noPermissionSpace: 'Não tem permissões para aceder a este espaço.',
    account: 'Conta',
    signout: 'Sair',
    send: 'Enviar',
    info: 'Informação',
    help: 'Ajuda',
    feedback: 'Sugestões',
    showMore: 'Mostrar mais',
    chooseSpace: 'Seleciona um espaço',
    changeLang: 'Mudar linguagem',
    logout: 'Terminar sessão',
    or: 'Ou',
    tutorial: 'Tutoriais',
    confirm: 'Confirmar',
    internetWentWrong: 'Algo não correu bem. Por favor verifique a sua conexão à internet e tente novamente',
    month: 'mês',
    save: 'Guardar',
    appKeywords: {
      classes: 'Aulas',
      tests: 'Testes',
      threads: 'Fóruns',
      admin: 'Administrador',
      lesson: 'Aula',
      subject: 'Área disciplinar',
      'class': 'Turma',
      student: 'Estudante',
      students: 'Estudantes',
      grade: 'Nota',
      personalAccount: 'Conta pessoal',
      personalSpace: 'Espaço pessoal',
      groups: 'Grupos',
      exercises: 'Exercícios',
      explore: 'Explorar',
      assessments: 'Avaliações',
      rubrics: 'Rubricas',
      events: 'Eventos',
      synchronization: 'Sincronização',
      teachers: 'Professores',
      exams: 'Exames',
    },
    login: {
      header: 'Iniciar sessão',
      description: 'Bem-vindo à Intuitivo',
      emailLabel: 'E-mail ou nome de utilizador',
      emailPlaceholder: 'Insere aqui o teu e-mail ou nome de utilizador',
      emailError: 'Tens de preencher este campo com o teu e-mail.',
      passwordLabel: 'Palavra-passe',
      passwordPlaceholder: 'Insere aqui a tua palavra-passe',
      passwordError: 'Tens de preencher este campo com a tua password.',
      submitLogin: 'Iniciar sessão',
      wrongCredentials: 'O teu e-mail ou password não correspondem',
      forgotPassword: 'Esqueci-me da minha palavra-passe',
      dontHaveAccount: 'Não tens uma conta?',
      createOne: 'Cria uma',
      loginWithMicrosoft: 'Iniciar sessão com a Microsoft',
      loginWithMicrosoftError: 'Inicie sessão com a sua conta Microsoft por favor',
      loginWithMicrosoftNoAccount: 'Não existe nenhuma conta com esse e-mail. Crie uma conta agora!',
      loginWithMicrosoftRegularAccountExists: 'Esta conta não está conectada à Microsoft. Inicie sessão com as suas credenciais!',
      exceededMaxAttempts: 'Você excedeu o máximo de tentativas de autenticação. Clique em esqueci-me da minha palavra-passe para continuar',
      poweredBy: 'Powered by',
    },
    register: {
      header: 'Criar conta',
      description: 'Bem-vindo à Intuitivo',
      nameLabel: 'Nome',
      namePlaceholder: 'Insere aqui o teu nome',
      nameEmptyError: 'O nome não pode estar vazio',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insere aqui o teu e-mail',
      emailEmptyError: 'O e-mail não pode ficar vazio',
      emailInvalidError: 'O e-mail introduzido não é válido',
      emailExistsError: 'Já existe uma conta com o e-mail introduzido',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insere aqui a tua password',
      passwordLengthError: 'A password deve conter pelo menos 8 caracteres',
      passwordSpecialCharsError: 'A password deve conter pelo menos um caracter especial',
      passwordUpperCharsError: 'A password deve conter pelo menos uma letra maiúscula',
      passwordNumberError: 'A password deve conter pelo menos um número',
      repeatPasswordLabel: 'Repetir password',
      repeatPasswordPlaceholder: 'Insere aqui a tua password',
      passwordsDifferentError: 'As passwords devem ser iguais',
      createAccount: 'Criar conta',
      alreadyHaveAccount: 'Já tens uma conta?',
      login: 'Inicia sessão',
      registerWithMicrosoft: 'Criar conta com a Microsoft',
    },
    onboarding: {
      title: 'Bem-vindo',
      header: 'Antes de continuar, ajude-nos a melhorar a sua experiência',
      doThisLater: 'Preencher mais tarde',
    },
    recover: {
      header: 'Recuperar password',
      description: 'Um e-mail será enviado para o seu endereço de e-mail onde poderá mudar a sua password.',
      descriptionPassword: 'Escreve aqui uma nova password para substituir a tua atual.',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Insere aqui o teu e-mail',
      emailSent: 'O e-mail foi enviado com sucesso. Por favor, verifique a sua caixa de correio',
      emailError: 'O e-mail fornecido está incorreto.',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Insere aqui a tua password',
      passwordRepeatLabel: 'Repetir Password',
      passwordRepeatPlaceholder: 'Insere aqui a tua password de novo',
      passwordUpdated: 'A password foi atualizada com sucesso',
      passwordMinimumChars: 'A password deve conter pelo menos 8 caracteres, uma letra e um algarismo.',
      passwordLengthError: 'A password deve conter pelo menos 8 caracteres',
      passwordSpecialCharsError: 'A password deve conter pelo menos um caracter especial',
      passwordUpperCharsError: 'A password deve conter pelo menos uma letra maiúscula',
      passwordNumberError: 'A password deve conter pelo menos um número',
      passwordsDifferentError: 'As passwords devem ser iguais',
    },
    profile: {
      header: 'Gestão de conta',
      description: 'Aqui podes gerir a tua conta',
      fullName: 'Nome',
      email: 'E-mail',
      updatePassword: 'Mudar password',
      passwordMissing: 'Ambos os campos devem ser preenchidos',
      passwordError: 'As passwords não coincidem',
      passwordHint: 'Utilizar 8 ou mais caracteres com uma mistura de letras, números e caracteres especiais',
      newPassword: 'Nova password',
      confirmPassword: 'Confirmar nova password',
      newPasswordPlaceholder: 'Escreve aqui a nova password',
      confirmPasswordPlaceholder: 'Escreve aqui novamente a nova password',
      userName: 'Nome de utilizador',
      editInformation: 'Editar informação',
      nameRequiredError: 'O nome é obrigatório',
      emailRequiredError: 'O e-mail é obrigatório',
      emailInvalidError: 'O e-mail introduzido é inválido',
      profileInformation: {
        informationUpdateSuccess: 'A sua informação foi atualizada com sucesso',
      },
      profilePreferences: {
        profilePreferences: 'Preferências',
        subjects: 'Áreas Disciplinares',
        years: 'Anos',
        country: 'País',
        curriculum: 'Currículo',
        selectCountry: 'Selecione um país',
        selectCurriculum: 'Selecione um currículo',
        editProfilePreferences: 'Editar preferências',
        noSubjects: 'Não tem nenhuma área disciplinar selecionada',
        noYears: 'Não tem nenhum ano selecionado',
        personalSpace: 'Espaço pessoal',
        personalSpaceDescription: 'O espaço pessoal é onde podes criar os teus conteúdos separados dos conteúdos da tua escola e onde podes criar grupos com outros membros da comunidade.',
        personalSpaceCheckboxLabel: 'Ativar espaço pessoal',
      },
      security: {
        securityHeader: 'Segurança',
        changePassword: 'Alterar palavra-passe',
        linkWithMicrosoft: 'Conectar com a Microsoft',
        unlinkMicrosoftAccount: 'Desconectar conta Microsoft',
        unlinkMicrosoftAccountInfo: 'Tem a certeza que quer desconectar a sua conta Microsoft? Poderá reconectar a qualquer altura no futuro.',
        unlinkMicrosoftAccountSuccess: 'A sua conta Microsoft foi desconectada com sucesso',
        unlinkMicrosoftAccountDisabled: 'Tem de criar uma palavra-passe antes de desconectar a sua conta Microsoft',
        emailAlreadyInUse: 'Este e-mail já está a ser utilizado por outro utilizador',
        accountLinkedWithMicrosoftSuccess: 'A sua conta Microsoft foi conectada com sucesso',
        createPassword: 'Criar palavra-passe',
      },
      plans: {
        plan: 'Meu plano',
        plans: 'Planos',
        free: 'Grátis',
        basic: 'Básico',
        schools: 'Escolas',
        premium: 'Premium',
        forTeachers: 'Para professores',
        forSchools: 'Para instituições',
        nextPayment: 'Próximo pagamento',
        onRequest: 'Sob consulta',
        contactUs: 'Contacte-nos!',
        recommendSchool: 'Recomendar escola',
        switchToPlan: 'Mudar para este plano',
        switchPlan: 'Gerir plano',
        cancelSubscription: 'Cancelar subscrição',
        cancelTip: (endDate) => `Vai manter todas as funcionalidades Premium até ao fim da subscrição em ${endDate}`,
        continueSubscription: 'Retomar subscrição',
        subscribedUntil: 'Com subscrição até',
        planDescription: {
          free: 'O essencial da Intuitivo, disponível para todos.',
          premium: 'A melhor ferramenta de avaliação, sem limites.',
          schools: 'A solução completa para instituições de ensino.',
        },
        monthly: 'Mensal',
        yearly: 'Anual',
        billedMonthly: 'pago mensalmente',
        billedYearly: 'pago anualmente',
        yourSubscription: 'A sua subscrição',
        total: 'Total',
        andPay: 'e pagar',
        taxInfo: ' de IVA incluído',
        includedTax: 'IVA inc.',
        alreadyOnThatPlan: 'Já tem uma subscrição desse plano',
        discountPrimaryText: (discountName) => `${discountName} com `,
        discountHighlightText: (limitDate) => `desconto até ${limitDate}!`,
        discountTooltip: (discountName, limitDate, yearlyDiscount, monthlyDiscount) => `${discountName} com desconto até ${limitDate}, com ${yearlyDiscount}% de desconto no plano anual${monthlyDiscount ? ` e ${monthlyDiscount}% no plano mensal.` : '.'}`,
        premiumUser: 'Utilizador Premium',
        getPremium: 'Obter Premium',
        schoolsFeatures: {
          premium: 'Tudo do plano premium',
          adminPanel: 'Painel de administração',
          schoolSpace: 'Espaço exclusivo da instituição',
          support: 'Suporte prioritário e formação',
        },
        paymentMethod: 'Método de pagamento',
        seePaymentMethod: 'Ver método de pagamento',
        replacePaymentMethod: 'Substituir método de pagamento',
        paypalEmail: 'Email do PayPal: ',
        expires: 'Expira: ',
        cardEndingIn: (brand, last4) => `Cartão ${brand} acabado em ${last4}`,
        removePaymentMethod: 'Remover método de pagamento',
        addPaymentMethod: 'Adicionar método de pagamento',
        promoCode: 'Código promocional',
        apply: 'Aplicar',
        promoCodePlaceholder: 'Insira aqui o seu código promocional',
        promoCodeInvalidError: 'O código promocional expirou ou é inválido',
      },
      danger: {
        dangerHeader: 'Zona de perigo',
        curriculum: 'Currículo',
        changeCurriculum: 'Mudar currículo',
        changeCurriculumWarning: 'Tem a certeza que quer mudar de currículo? Mudar de currículo vai fazer com que todos os seus exercícios e avaliações públicas voltem para privados. Os conteúdos da página de explorar vão passar a refletir o novo currículo.',
      },
    },
    schoolInvite: {
      header: 'Bem-vindo à Intuitivo',
      description1: 'Recebeu um convite da sua escola:',
      description2: 'Crie uma conta e comece já a colaborar!',
      continueWith: 'Continuar com',
      changeAccount1: 'Esta conta não é sua?',
      changeAccount2: 'Sair',
    },
    maintenance: {
      label: 'Manutenção',
      quote: 'O Tobias está a trabalhar arduamente para trazer a Intuitivo de volta, por favor volte mais tarde...',
    },
    features: {
      free: {
        'create-test': (limit) => `Crie até ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'}`,
        'create-exercise': (limit) => `Crie até ${limit} ${limit === 1 ? 'exercício' : 'exercícios'}`,
        'create-rubric': (limit) => `Crie até ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'}`,
        'create-group': (limit) => `Crie até ${limit} ${limit === 1 ? 'grupo' : 'grupos'}`,
        sections: (limit) => `Crie até ${limit} ${limit === 1 ? 'secção' : 'secções'}`,
        'export-versions': (limit) => `Exporte até ${limit} ${limit === 1 ? 'versão' : 'versões'}`,
      },
      premium: {
        'create-test': () => 'Crie avaliações ilimitadas',
        'create-exercise': () => 'Crie exercícios ilimitados',
        'create-rubric': () => 'Crie rubricas ilimitadas',
        'create-group': () => 'Crie grupos ilimitados',
        sections: () => 'Crie secções ilimitadas',
        'section-timer': () => 'Use temporizadores nas secções',
        navigation: () => 'Crie avaliações e secções não lineares',
        connecting: () => 'Publique testes com exercícios de conectar',
        segmentation: () => 'Publique testes com exercícios de segmentação',
        'hide-intuitivo-logo-export': () => 'Exporte avaliações e resoluções sem o logo da Intuitivo',
        feedback: () => 'Dê feedback aos seus estudantes',
        'export-versions': () => 'Exporte versões ilimitadas',
        'advanced-statistics': () => 'Estatísticas de avaliação avançadas',
      },
    },
    limbo: {
      title: 'Nenhum espaço encontrado!',
      description: 'O espaço da sua escola na Intuitivo foi desativado! Se pretender continuar a usar a Intuitivo de forma independente, com o nosso plano gratuito e limitado, crie um espaço pessoal clicando no botão abaixo e aceda às definições para alterar o e-mail para o seu e-mail pessoal, caso ainda não o tenha feito.',
      addPersonalSpace: 'Adicionar espaço pessoal',
    },
    curricula: {
      pt_PT: 'Português',
      other: 'Outro',
    },
  },
});

export default lang;
